<template>
  <!-- Coming soon page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
    </b-link>
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{$t('Welcome!')}}</h2>
        <p class="mb-3">
          {{$t('You can')}} <a href="login">{{$t('Sign In')}}</a> {{$t('or')}} {{$t('enter your email to add a new contract')}}
        </p>
        <!-- form -->
        <validation-observer
          #default="{ handleSubmit, invalid }"
          ref="refFormObserver"
        >
          <b-form
            inline
            class="row justify-content-center m-0 mb-2"
            @submit.prevent="handleSubmit(onNewKunde)"
          >
            <div class="email-selector col-12 col-md-6 mb-1 mr-md-2">
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required"
              >
                <v-select
                  ref="email"
                  v-model="selectedEmail"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="emails"
                  :reduce="(val) => val.id"
                  label="emailadresse"
                  :clearable="false"
                  input-id="notify-email"
                  class="email-selector"
                  :state="getValidationState(validationContext)"
                >
                  <template slot="selected-option" slot-scope="option">
                    <span>{{ option.nachname }} {{ option.vorname }}</span>
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.nachname }} {{ option.vorname }}
                  </template>
                </v-select>
              </validation-provider>
            </div>
            <b-button
              variant="primary"
              class="mb-1 btn-sm-block"
              type="submit"
              :disabled="invalid"
            >
              {{$t('New Customer')}}
            </b-button>
          </b-form>
        </validation-observer>
        <b-img fluid :src="imgUrl" alt="Coming soon page" />
      </div>
    </div>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ref, onUnmounted } from "@vue/composition-api";
import { required, email } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import employeeStoreModule from "../views/employee/employeeStoreModule";
import _ from "lodash";

export default {
  components: {
    VuexyLogo,
    BLink,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BImg,
    vSelect,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedEmail: "",
      required,
      email,
      downImg: require("@/assets/images/pages/coming-soon.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/coming-soon-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
  },
  setup(props, { emit }) {
    const STAFF_STORE_MODULE_NAME = "app-employee";

    // Register module
    if (!store.hasModule(STAFF_STORE_MODULE_NAME))
      store.registerModule(STAFF_STORE_MODULE_NAME, employeeStoreModule);

    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(STAFF_STORE_MODULE_NAME))
        store.unregisterModule(STAFF_STORE_MODULE_NAME);
    });

    const emailLocal = ref(JSON.parse(JSON.stringify("")));
    const resetEmailLocal = () => {
      emailLocal.value = JSON.parse(JSON.stringify(""));
    };

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetEmailLocal, resetEmailLocal);

    const emails = ref([]);

    store.dispatch("app-employee/fetchEmployees", {}).then((response) => {
      const responseData = response.data;

      emails.value = responseData.data;
    });

    return {
      emailLocal,
      emails,

      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    };
  },
  methods: {
    onNewKunde() {
      let routeData = this.$router.resolve({
        name: "customers-add",
        params: { mitarbeiter_id: this.$refs.email.value },
      });

      window.open(routeData.href, "_self");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@import "@core/scss/vue/libs/vue-select.scss";

a {
  outline: none;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.email-selector {
  padding: 0;
}

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
